import { Injectable } from '@angular/core';
import { NotificationType } from '@core/components/notification/models/notification.enum';
import { NotificationService } from '@core/components/notification/services/notification.service';
import { fromEvent, merge, startWith } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConnectionStatusService {
  // Create an observable that emits true when online and false when offline
  private online$ = fromEvent(globalThis, 'online').pipe(
    tap(() => this.notificationService.notify(NotificationType.Success, 'noConnection.notification.online')),
    map(() => true),
  );

  private offline$ = fromEvent(globalThis, 'offline').pipe(
    tap(() =>
      this.notificationService.notify(NotificationType.Error, 'noConnection.notification.offline', {
        actionCallback: undefined,
      }),
    ),
    map(() => false),
  );

  public connectionStatus$ = merge(this.online$, this.offline$).pipe(startWith(navigator.onLine));

  public constructor(private notificationService: NotificationService) {}
}
