<ng-container *transloco="let t">
  <div class="organization-select">
    @if (showSelect) {
      <p class="organization-select-label">{{ t('navigation.selectOrganization') }}</p>
      <nz-select
        class="organization-select-dropdown"
        nzShowSearch
        [nzPlaceHolder]="selectedOrganization?.organizationDisplayName || t('navigation.selectOrganization')"
        [ngModel]="selectedOrganizationId"
        (ngModelChange)="onSelectionChange($event)"
      >
        @for (organization of organizations; track organization) {
          <nz-option [nzValue]="organization.organizationId" [nzLabel]="organization.organizationDisplayName!">
          </nz-option>
        }
      </nz-select>
    } @else if (defaultDisplayNameCompanyRiskAnalysis) {
      <div class="organization-select-just-name">
        <span>{{ defaultDisplayNameCompanyRiskAnalysis }}</span>
      </div>
    }
  </div>
</ng-container>
