import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppContract } from '@core/models/contract.model';
import { environment } from '@env/environment';
import { TranslocoDirective } from '@jsverse/transloco';
import { ResponseUserGrantDto } from '@kerberos-compliance/lib-adp-shared/core/iam/authorization/dtos/response.user-grant.dto';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-organization-select',
  imports: [TranslocoDirective, NzSelectComponent, FormsModule, NzOptionComponent],
  styleUrls: ['./organization-select.component.scss'],
  templateUrl: './organization-select.component.html',
})
export class OrganizationSelectComponent implements OnInit {
  @Input() public ownGrant$!: Observable<ResponseUserGrantDto[] | undefined>;

  @Output() public selectionChange = new EventEmitter<string>();

  public organizations: ResponseUserGrantDto[] = [];

  public showSelect: boolean = false;

  public selectedOrganizationId?: string;

  public defaultDisplayNameCompanyRiskAnalysis?: string;

  public selectedOrganization?: ResponseUserGrantDto;

  public ngOnInit(): void {
    this.ownGrant$.pipe(map((grants) => this.processOrganizationGrants(grants))).subscribe();
  }

  /**
   * Processes the organization grants by filtering valid grants,
   * creating unique organizations, and updating the organization selection.
   *
   * @param {ResponseUserGrantDto[] | undefined} grants - An array of user grants or undefined, representing the grants to process.
   * @return {void} This method does not return a value.
   */
  private processOrganizationGrants(grants: ResponseUserGrantDto[] | undefined): void {
    const validGrants = this.getValidOrganizationGrants(grants!);
    this.organizations = this.createUniqueOrganizations(validGrants);
    this.updateOrganizationSelection();
  }

  /**
   * Filters the given grants to return only those associated with a valid organization ID.
   *
   * @param {ResponseUserGrantDto[] | null} grants - The list of user grants to filter. Can be null or an array of UserGrant objects.
   * @return {ResponseUserGrantDto[]} An array of UserGrant objects that have a valid organization ID.
   */
  private getValidOrganizationGrants(grants: ResponseUserGrantDto[] | null): ResponseUserGrantDto[] {
    return (grants || []).filter((grant) => !!grant.organizationId);
  }

  /**
   * Creates a unique list of user grants based on organization IDs.
   * Only the first occurrence of a grant for each organization ID is retained.
   *
   * @param {ResponseUserGrantDto[]} grants - An array of user grant objects to filter for uniqueness by organization.
   * @return {ResponseUserGrantDto[]} An array of unique user grants, filtered by their organization ID.
   */
  private createUniqueOrganizations(grants: ResponseUserGrantDto[]): ResponseUserGrantDto[] {
    const organizationGrantMap = new Map<string, ResponseUserGrantDto>();
    for (const grant of grants) {
      if (grant.organizationId && !organizationGrantMap.has(grant.organizationId)) {
        organizationGrantMap.set(grant.organizationId, grant);
      }
    }
    return [...organizationGrantMap.values()];
  }

  /**
   * Updates the currently selected organization based on the available organizations.
   * Determines whether to show the selection drop-down and assigns the first organization
   * as selected by default if there are any organizations available.
   * Depending on the selected organization, it either loads contract information or sets
   * a default display name.
   *
   * @return {void} This method does not return any value.
   */
  private updateOrganizationSelection(): void {
    this.showSelect = this.organizations.length > 1;

    if (this.organizations.length > 0) {
      const firstOrganization = this.organizations[0];
      this.selectedOrganizationId = firstOrganization.organizationId!;
      this.selectedOrganization = firstOrganization;

      if (firstOrganization.organizationId === environment.config.defaultOrganizationRiskAnalysisIdentifier) {
        this.loadContractInfo();
      } else {
        this.defaultDisplayNameCompanyRiskAnalysis = firstOrganization.organizationDisplayName;
      }
    }

    if (!this.showSelect) {
      this.loadContractInfo();
    }
  }

  /**
   * Handles the selection change event for organizations. Updates the selected organization ID
   * and the corresponding organization data, then emits the selection change event.
   *
   * @param {string} selectedOrganizationId - The ID of the newly selected organization.
   * @return {void} This method does not return a value.
   */
  public onSelectionChange(selectedOrganizationId: string): void {
    this.selectionChange.emit(selectedOrganizationId);
    this.selectedOrganizationId = selectedOrganizationId;
    this.selectedOrganization = this.organizations.find(
      (organization) => organization.organizationId === selectedOrganizationId,
    );
  }

  /**
   * Loads contract information from localStorage, parses it,
   * and updates the default display name for company risk analysis.
   * The method retrieves contract data stored in localStorage,
   * parses the JSON data, and sets the company account name
   * if available. Logs an error message if parsing fails.
   *
   * @return {void} Does not return a value.
   */
  private loadContractInfo(): void {
    const contractJson = localStorage.getItem('contract');
    if (!contractJson) return;

    try {
      const parsedContract = JSON.parse(contractJson) as AppContract;
      this.defaultDisplayNameCompanyRiskAnalysis = parsedContract?.contract?.accountName ?? '';
    } catch (error) {
      console.error('Failed to parse contract information:', error);
    }
  }
}
