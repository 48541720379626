{
  "name": "cop-app",
  "version": "0.19.0-rc.3",
  "type": "module",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:clean": "rimraf .angular && rimraf node_modules/.cache && ng serve",
    "start:clean:install": "rimraf .angular && rimraf node_modules/.cache && pnpm i && ng serve",
    "watch": "ng build --watch --configuration development",
    "test": "jest",
    "test:coverage": "jest --coverage",
    "test:ci": "jest --coverage",
    "build:ci": "cross-env NODE_ENV=$ANGULAR_CONFIGURATION ng build --configuration $ANGULAR_CONFIGURATION",
    "build:dev": "ng build --output-hashing all --configuration development",
    "build:prod:stats": "cross-env NODE_ENV=production ng build --configuration production --stats-json",
    "analyze": "webpack-bundle-analyzer ./dist/stats.json",
    "build:analyze": "npm run build:prod:stats && npm run analyze",
    "prettier-all": "prettier --config .prettierrc.json --ignore-path .prettierignore --write '**/*.{js,ts,json,html,css,scss,md}'",
    "lint": "eslint src/**/*.ts src/**/*.component.html",
    "type:check": "tsc --noEmit",
    "e2e": "ng e2e",
    "docs:json": "compodoc -p ./tsconfig.json -e json -d .",
    "storybook": "ng run cop-app:storybook",
    "build-storybook": "ng run cop-app:build-storybook",
    "watch:lib": "cd ../aml-elements-lib && ng build --watch",
    "build:lib": "cd ../aml-elements-lib && ng build",
    "start:dev": "npm run build:lib && ng serve --watch",
    "dev": "npm-run-all --parallel watch:lib start:dev"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.1.6",
    "@angular/cdk": "19.1.4",
    "@angular/common": "19.1.6",
    "@angular/compiler": "19.1.6",
    "@angular/core": "19.1.6",
    "@angular/forms": "19.1.6",
    "@angular/material": "19.1.4",
    "@angular/platform-browser": "19.1.6",
    "@angular/platform-browser-dynamic": "19.1.6",
    "@angular/router": "19.1.6",
    "@ant-design/icons-angular": "~19.0.0",
    "@azure/msal-angular": "4.0.8",
    "@azure/msal-browser": "4.8.0",
    "@azure/msal-common": "15.4.0",
    "@bluehalo/ngx-leaflet": "18.0.2",
    "@jsverse/transloco": "7.5.0",
    "@kerberos-compliance/aml-elements-lib": "25.3.17",
    "@kerberos-compliance/lib-adp-shared": "25.3.11",
    "@ngrx/data": "19.0.1",
    "@ngrx/effects": "19.0.1",
    "@ngrx/entity": "19.0.1",
    "@ngrx/operators": "19.0.1",
    "@ngrx/store": "19.0.1",
    "@ngrx/store-devtools": "19.0.1",
    "@ngx-formly/core": "6.3.12",
    "@ngx-formly/ng-zorro-antd": "6.3.12",
    "@sentry/angular": "8.42.0",
    "@storybook/addon-actions": "~8.4.6",
    "@storybook/angular": "~8.4.6",
    "@tailwindcss/line-clamp": "~0.2.2",
    "daisyui": "~4.12.14",
    "dayjs": "1.11.11",
    "dotenv": "^16.4.5",
    "klaro": "0.7.22",
    "leaflet": "1.9.4",
    "ng-dynamic-component": "10.7.0",
    "ng-zorro-antd": "19.0.2",
    "rxfire": "~6.0.5",
    "rxjs": "~7.8.1",
    "ts-node": "10.9.2",
    "tslib": "~2.8.1",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "19.0.0",
    "@angular-devkit/architect": "0.1901.7",
    "@angular-devkit/build-angular": "19.1.7",
    "@angular-eslint/builder": "16.3.1",
    "@angular/cli": "19.1.7",
    "@angular/compiler-cli": "19.1.6",
    "@angular/elements": "19.1.6",
    "@babel/core": "^7.14.8",
    "@commitlint/cli": "~19.7.1",
    "@commitlint/config-conventional": "~19.7.1",
    "@compodoc/compodoc": "^1.1.19",
    "@kerberos-compliance/ts-tools-configs": "25.3.2-beta.1",
    "@ngrx/schematics": "~19.0.1",
    "@playwright/test": "1.48.2",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@storybook/addon-essentials": "~8.4.4",
    "@storybook/addon-links": "~8.4.4",
    "@types/jest": "^29.5.10",
    "@types/leaflet": "^1.9.14",
    "@types/node": "~18.15.3",
    "@typescript-eslint/eslint-plugin": "~8.26.0",
    "@typescript-eslint/parser": "~8.26.0",
    "@webcomponents/custom-elements": "^1.4.3",
    "autoprefixer": "^10.2.6",
    "babel-loader": "^8.2.5",
    "compression-webpack-plugin": "^9.2.0",
    "cross-env": "^7.0.3",
    "eslint": "~9.21.0",
    "eslint-import-resolver-node": "~0.3.9",
    "eslint-import-resolver-typescript": "~3.8.3",
    "eslint-interactive": "11.1.0",
    "eslint-plugin-storybook": "~0.11.1",
    "fuzzy": "^0.1.3",
    "husky": "~9.1.7",
    "identity-obj-proxy": "^3.0.0",
    "inquirer": "^6.2.2",
    "inquirer-autocomplete-prompt": "^1.0.2",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "~14.4.2",
    "jest-sonar": "^0.2.16",
    "jsonc-parser": "^3.0.0",
    "lint-staged": "~15.4.3",
    "ng-mocks": "^14.13.2",
    "npm-check-updates": "~17.1.15",
    "npm-run-all": "^4.1.5",
    "open": "^7.0.4",
    "playwright-ng-schematics": "1.1.1",
    "postcss": "^8.2.15",
    "prettier": "~3.5.3",
    "pretty-quick": "^4.0.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "rimraf": "^6.0.1",
    "semantic-release": "^22.0.12",
    "storybook": "~8.4.4",
    "storybook-addon-angular-router": "~1.10.1",
    "tailwindcss": "3.4.15",
    "ts-jest": "^29.1.1",
    "typescript": "~5.6.3",
    "webpack-bundle-analyzer": "4.5.0"
  },
  "engines": {
    "node": ">=22.0.0"
  }
}
